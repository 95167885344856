import React, { useEffect, useState, useCallback } from 'react';
import { graphql } from 'gatsby';
import { mergePrismicPreviewData } from 'gatsby-source-prismic';
import { Layout, Slice, TopBanner } from 'components';

const IS_BROWSER = typeof window !== 'undefined';

export const Page = ({ location, data: staticData, navigate }) => {
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__;
  const data = mergePrismicPreviewData({ staticData, previewData });
  const { page } = data;
  const { data: pageData } = page;

  const {
    body: sliceData,
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    schema,
  } = pageData;

  const seo = {
    title: metaTitle.text,
    desc: metaDesc.text,
    banner: ogImage && ogImage.localFile && ogImage.localFile.childImageSharp.gatsbyImageData.src,
    schema: schema.text,
  };

  const [darkMode, setDarkMode] = useState(true);

  const onScroll = useCallback(() => {
    const offsetAmount = 300;
    const { scrollY } = window;
    if (scrollY > offsetAmount && darkMode) {
      setDarkMode(false);
    } else if (scrollY < offsetAmount && !darkMode) {
      setDarkMode(true);
    }
  });

  useEffect(() => {
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, [darkMode]);

  return (
    <div className={darkMode ? 'dark-mode' : 'normal-mode'}>
      <TopBanner />
      <Layout location={location} seo={seo}>
        {sliceData.map((slice, index) => (
          <Slice key={slice.id} id={slice.id} data={slice} sliceIndex={index} location={location} navigate={navigate} />
        ))}
      </Layout>
    </div>
  );
};

export default Page;

export const pageQuery = graphql`
  query PageBySlug($uid: String!) {
    page: prismicPage(uid: { eq: $uid }) {
      id
      uid
      prismicId
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        schema {
          text
        }
        body {
          ...TwoColsTextImagePageFragment
          ...FeatureGridPageFragment
          ...ImageGridPageFragment
          ...ImageStatsOverlayPageFragment
          ...ListImageBreakoutPageFragment
          ...ThreeColsTestimonialPageFragment
          ...MemberLogoGridPageFragment
          ...PageMemberTestimonial
          ...BlogPostsPageFragment
          ...HeroPageFragment
          ...HubspotPageFragment
          ...PageFaqAccordion
          ...PageTwoCols
          ...GridPageFragment
          ...TextCarouselPageFragment
          ...PageTextColumns
          ...PageForm
        }
      }
    }
  }
`;
